import _Cookies2 from "./Cookies";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _Cookies = _interopRequireDefault(_Cookies2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var _default = _Cookies["default"];
exports["default"] = _default;
exports = exports.default;
export default exports;
export const __esModule = exports.__esModule;